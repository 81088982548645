@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap');

// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "volt/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Vendor
@import "volt/vendor";

// volt mixins & functions
@import "volt/mixins";
@import "volt/functions";

// Utilities
@import "volt/reboot";
@import "volt/utilities";

// Layout
@import "volt/layout";

// Components
@import "volt/components";

// write your custom styles here!
  img.hover-zoom {
    max-width: 145%;
    width: 192%;
    left: -20%;
    top: -6%;
    position: absolute;
  }

  img.hover-zoom2 {
    max-width: 145%;
    width: 192%;
    left: -20%;
    top: -32%;
    position: absolute;
  }